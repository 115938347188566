<template>
  <b-card>
    <ValidationObserver>
      <form @submit.prevent="saveItem">
        <b-row>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="title">Title:</label>
              <validation-provider
                name="title"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="title"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formPayload.title"
                  placeholder="Judul"
                />
                <small
                  v-for="(validation, index) in validations.title"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="category">Category:</label>
              <br />
              <small>Jika tidak ada dalam daftar silahkan ketik:</small>
              <validation-provider name="category" rules="required">
                <v-select
                  id="group"
                  label="name"
                  v-model="selectedOption"
                  :options="postCategories"
                  placeholder="-- Pilih --"
                  :reduce="(postCategories) => postCategories.uuid"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  @keyup.stop.native="searchCategories"
                >
                  <template #option="{ name, group }">
                    <div class="d-flex align-items-center" style="gap: 6px">
                      <p class="mb-0">{{ name }}</p>
                      <p class="text-capitalize mb-0">({{ group || "-" }})</p>
                    </div>
                  </template>
                </v-select>
                <small class="text-danger">{{ validationsCategory }}</small>
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="content">Content:</label>
              <validation-provider name="category" rules="required">
                <quill-editor 
                  id="content" 
                  v-model="formPayload.content"
                  :options="editorOption"
                  @ready="onEditorReady"
                />
                <small
                  v-for="(validation, index) in validations.content"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <div class="demo-inline-spacing" style="margin-top: 8px">
                <div class="custom-control custom-radio mt-0">
                  <input
                    name="inlineRadioOptions"
                    class="custom-control-input"
                    type="radio"
                    id="radio1"
                    value="file"
                    v-model="fileContent"
                  />
                  <label class="custom-control-label" for="radio1">
                    Image/Video
                  </label>
                </div>
                <div class="custom-control custom-radio mt-0">
                  <input
                    name="inlineRadioOptions"
                    class="custom-control-input"
                    type="radio"
                    id="radio2"
                    value="youtube"
                    v-model="fileContent"
                  />
                  <label class="custom-control-label" for="radio2">
                    Youtube ID
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group" v-if="fileContent == 'file'">
              <label for="">Image / Video:</label><br />
              <p class="image__hint"><i>Max 5 mb</i></p>
              <validation-provider name="image" rules="required">
                <b-form-file
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  @change="changeFile($event)"
                />
                <small>Current file:</small><br />
                <img
                  v-if="
                    (file_url !== null && file_type == 'image') ||
                    file_type == 'jpeg' ||
                    file_type == 'png' ||
                    file_type == 'jpg'
                  "
                  :src="file_url"
                  class="media_file"
                />
                <video
                  class="media_file"
                  controls
                  v-else-if="
                    (file_url !== null && file_type == 'video') ||
                    file_type == 'mp4' ||
                    file_type == 'flv' ||
                    file_type == 'mkv' ||
                    file_type == '3gp'
                  "
                >
                  <source :src="file_url" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <small
                  v-for="(validation, index) in validations.content_file"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group" v-if="fileContent == 'youtube'">
              <label for="">Youtube ID:</label><br />
              <validation-provider
                name="image"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Youtube ID"
                  v-model="formPayload.youtube_id"
                />
                <small class="text-danger">{{ validationsCategory }}</small>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="status"> Status: </label>
              <validation-provider name="status" rules="required">
                <v-select
                  id="status"
                  label="title"
                  v-model="formPayload.status"
                  :options="option"
                  placeholder="-- Pilih Status --"
                  :reduce="(option) => option.value"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <small
                  v-for="(validation, index) in validations.status"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="tags"> Tags: </label><br>
              <small>Pisahkan dengan koma (,)</small>
              <input
                type="text"
                class="form-control"
                v-model="formPayload.tags"
              />
            </div>
            <div class="form-group">
              <label for="type">Headline:</label>
              <div class="demo-inline-spacing" style="margin-top: 8px">
                <div class="custom-control custom-radio mt-0">
                  <input
                    name="headlineOptions"
                    class="custom-control-input"
                    type="radio"
                    id="radio3"
                    value="true"
                    v-model="formPayload.is_headline"
                  />
                  <label class="custom-control-label" for="radio3"> Yes </label>
                </div>
                <div class="custom-control custom-radio mt-0">
                  <input
                    name="headlineOptions"
                    class="custom-control-input"
                    type="radio"
                    id="radio4"
                    value="false"
                    v-model="formPayload.is_headline"
                  />
                  <label class="custom-control-label" for="radio4"> No </label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="type">Pinned:</label>
              <div class="demo-inline-spacing" style="margin-top: 8px">
                <div class="custom-control custom-radio mt-0">
                  <input
                    name="pinnedOptions"
                    class="custom-control-input"
                    type="radio"
                    id="radio5"
                    value="true"
                    v-model="formPayload.is_pinned"
                  />
                  <label class="custom-control-label" for="radio5"> Yes </label>
                </div>
                <div class="custom-control custom-radio mt-0">
                  <input
                    name="pinnedOptions"
                    class="custom-control-input"
                    type="radio"
                    id="radio6"
                    value="false"
                    v-model="formPayload.is_pinned"
                  />
                  <label class="custom-control-label" for="radio6"> No </label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="tags"> Meta Description: </label>
              <input
                type="text"
                class="form-control"
                v-model="formPayload.meta_description"
                placeholder="Meta Description"
              />
            </div>
            <div class="form-group">
              <label for="tags"> Meta Keyword: </label>
              <input
                type="text"
                class="form-control"
                v-model="formPayload.meta_keyword"
                placeholder="Meta Keyword"
              />
            </div>
            <div class="form-group">
              <label for="publishDate">Publish Date:</label>
              <validation-provider name="publishDate" rules="required">
                <flat-pickr
                  id="publishDate"
                  v-model="formPayload.publish_date"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss' }"
                />
                <small
                  v-for="(validation, index) in validations.publish_date"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
          </b-col>
        </b-row>

        <b-col cols="12" class="p-0 mt-2">
          <button
            variant="primary"
            class="btn waves-effect waves-float waves-light btn-primary"
            :disabled="isLoading"
          >
            {{ isLoading ? "Submitting..." : "Submit" }}
          </button>
          &nbsp; &nbsp;
          <a
            @click="handleBackButton()"
            variant="secondary"
            class="btn waves-effect waves-float waves-light btn-secondary"
          >
            Cancel
          </a>
        </b-col>
      </form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import _ from "lodash";
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BRow,
  BCol,
  BFormFile,
  BFormCheckbox,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    vSelect,
    quillEditor,
    BFormFile,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  data() {
    return {
      required,
      moment,
      isLoading: false,
      formPayload: {
        title: "",
        category_uuid: "",
        content: "",
        youtube_id: "",
        content_file: "",
        status: "",
        tags: "",
        is_headline: false,
        is_pinned: false,
        meta_description: "",
        meta_keyword: "",
        publish_date: "",
      },
      option: [
        { title: "Publish", value: "publish" },
        { title: "Waiting Approval", value: "waiting_approval" },
        { title: "Rejected", value: "rejected" },
      ],
      selectedOption: "",
      file_url: null,
      file_type: "",
      fileContent: "",
      postCategories: [],
      validations: "",
      validationsCategory: "",
      // Must be an array reference!
      //Quill Editor Option
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              ['link', 'image', 'formula'],

              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              [{ 'indent': '-1'}, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],

              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              [{ 'color': [] }, { 'background': [] }],
              [{ 'font': [] }],
              [{ 'align': [] }],

              ['clean']   
            ],
            handlers: {
              image: this.imageHandler, // Custom image handler
            },
          },
        },
      },
    };
  },

  mounted() {
    this.loadCategory();
  },

  created() {
    this.loadPost();
  },

  methods: {
    handleBackButton() {
      history.back();
    },
    loadPost() {
      const params = this.$route.params.uuid;
      this.$http
        .get("/api/v1/admin/posts/detail/" + params)
        .then((response) => {
          this.formPayload = response.data.data;
          this.selectedOption = this.formPayload.category;
          if (this.formPayload.content_file.image !== null) {
            this.file_url = this.formPayload.content_file.image;
            this.file_type = this.file_url.substr(
              this.file_url.lastIndexOf(".") + 1
            );
            this.fileContent = "file";
          } else if (this.formPayload.content_file.video !== null) {
            this.file_url = this.formPayload.content_file.video;
            this.file_type = this.file_url.substr(
              this.file_url.lastIndexOf(".") + 1
            );
            this.fileContent = "file";
          } else if (this.formPayload.content_file.youtube_id !== null) {
            this.formPayload.youtube_id =
              this.formPayload.content_file.youtube_id;
            console.log(this.formPayload.youtube_id, "data");
            this.fileContent = "youtube";
          }
          if (this.formPayload.publish_date === null) {
            this.formPayload.publish_date = moment().format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
        });
    },
    changeFile(event) {
      this.formPayload.content_file = event.target.files[0];
      this.showFile(event.target.files[0]);
    },
    showFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.file_url = e.target.result;
        var str = e.target.result;
        var startIndex = str.indexOf("/") + 1; // Menentukan indeks mulai setelah karakter '/'
        var endIndex = str.indexOf(";"); // Menentukan indeks akhir sebelum karakter ';'
        var imageType = str.substring(startIndex, endIndex); // Memotong string berdasarkan indeks mulai dan akhir
        this.file_type = imageType;
      };
      reader.readAsDataURL(file);
    },
    searchCategories: _.debounce(function (e) {
      const search = e.target.value;
      this.loadCategory(search);
    }, 300),
    loadCategory(search) {
      this.$http
        .get("/api/v1/admin/post-categories/list", {
          params: {
            search: search != null ? search : null,
          },
        })
        .then((response) => {
          this.postCategories = response.data.data.items;
        });
    },
    saveItem() {
      this.isLoading = true;
      this.validations = "";
      this.validationsCategory = "";
      const params = this.$route.params.uuid;
      if (this.fileContent == "file") {
        this.formPayload.youtube_id = "";
      } else {
        this.formPayload.content_file = "";
      }
      this.selectedOption.uuid != null
        ? (this.formPayload.category_uuid = this.selectedOption.uuid)
        : (this.formPayload.category_uuid = this.selectedOption);
      const payload = new FormData();
      payload.append("title", this.formPayload.title);
      payload.append("category_uuid", this.formPayload.category_uuid);
      payload.append("content", this.formPayload.content);
      payload.append("youtube_id", this.formPayload.youtube_id);
      payload.append("content_file", this.formPayload.content_file);
      payload.append("status", this.formPayload.status);
      payload.append("tags", this.formPayload.tags);
      payload.append("is_headline", this.formPayload.is_headline);
      payload.append("is_pinned", this.formPayload.is_pinned);
      payload.append("meta_description", this.formPayload.meta_description);
      payload.append("meta_keyword", this.formPayload.meta_keyword);
      payload.append("publish_date", this.formPayload.publish_date);
      this.$http
        .post(`/api/v1/admin/posts/update/${params}`, payload, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          this.isLoading = false;
          successNotification(this, "Success", "Post Berhasil diedit!");
          this.$router.push({ name: "post" });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
            errorNotification(
              this,
              "Content",
              this.validations.content.join("")
            );
            errorNotification(
              this,
              "Publish Date",
              this.validations.publish_date.join("")
            );
            errorNotification(this, "Status", this.validations.status.join(""));
            errorNotification(this, "Title", this.validations.title.join(""));
          } else if (error.response.data.meta.messages) {
            this.validationsCategory = error.response.data.meta.messages;
            errorNotification(this, "Input Kosong!", this.validationsCategory);
          }
        });
    },
    onEditorReady(editor) {
      // Keep a reference to the editor instance
      this.quill = editor;
    },
    imageHandler() {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();

      input.onchange = async () => {
        const file = input.files[0];
        if (file) {
          /**
           * Upload file to Server
           */
          const fileUploadPayload = new FormData();
          fileUploadPayload.append('file', file);
          try {
            const uploadToServer = await this.$http.post("/api/v1/uploads", fileUploadPayload, {
              headers: { "Content-Type": "multipart/form-data" },
            });

            const uploadToServerResponse = uploadToServer.data.data.file_url;
            const range = this.quill.getSelection();
            setTimeout(() => {
              console.log('set image');
              this.quill.insertEmbed(range.index, 'image', uploadToServerResponse, 'silent');
            }, 3000);
          } catch (error) {
            console.log('Error quiil upload image :', error);
            errorNotification(this, `Upload file ${file.name} error`);
          }
        }
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}
.media_file {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
</style>
